import React from 'react';
import Layout from '../components/Layout';
import {Typography} from '@material-ui/core';
import MaterialLink from '../components/Link';


export default function NotFoundPage() {
  return (
    <Layout>
        <Typography variant="h3" component="h1" gutterBottom align='center'>
            404
        </Typography>
        <MaterialLink to="/">Go to the main page</MaterialLink>
    </Layout>
  );
}
